@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Rubik:wght@800&family=Ubuntu:wght@500&display=swap');


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root{
    --color-bg: #141a28;
    --color-primary: #0f605e;
    --color-bg-variant: #11383a;
    --color-primary-btn: #31b496;
    --color-black: #000;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.65);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --transition: all 400ms ease;

}



html{
    scroll-behavior: smooth;
}

/* ::-webkit-scrollbar{
    display: none;
} */

body{
    font-family: 'DM Sans', sans-serif;
    /* height: 100vh; */
    background: var(--color-bg);
    background: linear-gradient(0deg, var(--color-bg) 50%, var(--color-bg-variant) 100%) no-repeat ;
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg4.svg); */
}
.btn-green{
    background: var(--color-primary-btn);
    transition: var(--transition);
}


.sub-head{
    color: var(--color-light);
}

/*======================== GENEARL STYLES ========================*/
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5{
    font-weight: 700;
}

h1,
h2,
h3{
    font-family: 'Ubuntu', sans-serif;
    font-size: 32px;
}
h3{
    font-size: 26px;
}
section{
    margin-top: 8rem;
    /* height: 100vh; */
}

section > h2, section > h5{
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    /* color: var(--color-primary); */
    padding: 0.7rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    /* border: 1px solid var(--color-primary); */
    transition: var(--transition);
}



.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    border: 1px solid var(--color-primary);
    /* background: var(--color-primary); */
    /* color: var(--color-bg); */
}

.flex-btn{
    display: inline-flex !important;
    align-items: center;
}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}

.logo{
    height: 30px;
    width: 30px;
}

.header-img{
    height: auto;
    width: 100%;
}

.card-icon{
    height: 40px;
    width: 40px;
}

.footer-icon{
    width: 40px;
}

.footer{
    background: #183e41;
}

.walletbg{
    background: #31b496;
}
.walletHead{
    color: var(--color-bg-variant);
}

/*======================== MEDIA QUERIES (TABLET DEVICES) ========================*/
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/*======================== MEDIA QUERIES (MOBILE DEVICES) ========================*/
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-ms);
    }

    section > h2{
        margin-top: 2rem;
    }
}