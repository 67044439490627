.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    /* background-color: #fff; */
    color: #fff;
    /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
  }

  .brand-name {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }

  .navigation-menu li {
    /* // removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 0 1rem;
  }

  .navigation-menu li a {
    /* // increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .hamburger {
    /* // removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #31b496;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #31b496;
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
    .navigation-menu.expanded {
      z-index: 99;
  }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
      position: absolute;
      top: 60px;
      right: 0;
      display: block;
      /* // stacks the li tags vertically  */
      flex-direction: column;
     /* // makes menu span full height and width */
      width: 50%;
      /* float: right; */
      height: 100vh;
      background-color: rgb(15, 54, 56);
      /* border-top: 1px solid #31b496; */
      ;
    }
    .navigation-menu li {
      /* // centers link text and strips off margin */
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: #fff;
      /* // increases the surface area of the anchor tag to span the full width of the menu */
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
    }
  }